import { useFormik, FormikProps } from "formik";
import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { AllSubjects } from "../../data/subjects";
import SubjectComponent from "./subject.component";
import * as yup from "yup";
import Modal from "../result/modal";

interface InitialValueTypes {
  [key: string]: number;
}

const Subject = () => {
  // routes
  const { regType, depType, semType } = useParams();
  const currentSubjects: string =
    regType && depType && semType ? `${regType}${depType}${semType}` : "";
  const result: InitialValueTypes | any = AllSubjects[currentSubjects];

  // Seperating credits
  let subjectCredits: { [key: string]: number } = {};
  let initialValues: InitialValueTypes | any = {};

  const [open, setOpen] = useState(false);
  const [score, setScore] = useState(0);

  if (result) {
    result.map((item: { code: string; name: string; credit: number }) => {
      subjectCredits[item.code] = item.credit;
      return (initialValues[item.code] = "");
    });
  }

  // Yup validation
  const validationSchema = yup.lazy((initialValues) =>
    yup.object(
      Object.keys(initialValues).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: yup.string().trim().min(1).required(),
        }),
        {}
      )
    )
  );

  // Formik
  let formik: FormikProps<InitialValueTypes>;
  formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => doCalc(values, subjectCredits),
  });

  const doCalc = (
    grade: { [key: string]: string },
    subjectCredits: { [key: string]: number }
  ) => {
    let sumOfCredits = 0;
    let sumOfGrades = 0;
    for (let i in grade) {
      sumOfGrades += strToInt(grade[i]) * subjectCredits[i];
      sumOfCredits = strToInt(grade[i])
        ? sumOfCredits + subjectCredits[i]
        : sumOfCredits;
    }
    setScore(sumOfGrades / sumOfCredits);
    setOpen(true);
  };

  const strToInt = (grade: string) => parseInt(grade);

  return (
    <div className="px-3 lg:px-16">
      {open && <Modal score={score} open={open} setOpen={setOpen} />}
      <h1 className="py-10 text-2xl font-medium">Select your Grade</h1>
      <form
        onSubmit={formik.handleSubmit}
        className="px-3 py-4 border-4 border-teritary md:px-5 rounded-xl"
      >
        {result &&
          result.map((subject: any, index: number) => (
            <Fragment key={index}>
              <SubjectComponent
                name={subject?.code}
                index={index}
                formik={formik}
                subject={subject}
              />
            </Fragment>
          ))}
        <div className="flex justify-around mt-10 md:mt-20">
          <button
            type="reset"
            onClick={() => formik.handleReset()}
            className="px-6 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
          >
            Clear
          </button>
          <button
            type="submit"
            className="px-6 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
          >
            Submit
          </button>
        </div>
      </form>
      <br />
    </div>
  );
};

export default Subject;
