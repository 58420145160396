import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./components/error";

import DepartmentPage from "./pages/department";
import HomePage from "./pages/home";
import RegulationPage from "./pages/regulation";
import SemesterPage from "./pages/semester";
import SubjectPage from "./pages/subject";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/:calcType" element={<RegulationPage />} />
      <Route path="/:calcType/:regType" element={<DepartmentPage />} />
      <Route path="/:calcType/:regType/:depType" element={<SemesterPage />} />
      <Route
        path="/:calcType/:regType/:depType/:semType"
        element={<SubjectPage />}
      />
      <Route path="/error" element={<NotFound />} />
    </Routes>
  );
}

export default App;
