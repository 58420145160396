import React from "react";
import { Link, useParams } from "react-router-dom";

interface Props {}

const Department = (props: Props) => {
  const {calcType, regType } = useParams();
  const departmentList = [
    {
      name: "CSE",
      route: `/${calcType}/${regType}/cse`,
    },
    {
      name: "ECE",
      route: `/${calcType}/${regType}/ece`,
    },
    {
      name: "EEE",
      route: `/${calcType}/${regType}/eee`,
    },
    {
      name: "MECH",
      route: `/${calcType}/${regType}/mech`,
    },
    {
      name: "CIVIL",
      route: `/${calcType}/${regType}/civil`,
    },
  ];
  return (
    <div>
      <div className="px-5 lg:px-16">
        <h1 className="py-10 text-2xl font-medium">Select your Department</h1>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ">
          {departmentList.map((dept, index) => (
            <Link to={dept.route} key={index}>
              <div
                key={index}
              className="flex flex-col items-center justify-center w-40 h-32 my-4 font-semibold text-center text-white bg-green-500 rounded-lg shadow-2xl cursor-pointer md:mb-10 md:w-52 hover:bg-green-600"
               
              >
                <h2>{dept.name}</h2>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Department;
