import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function BreadCrumbs() {
  const navigate = useNavigate();
  const { calcType, regType, depType, semType } = useParams();

  const pages = [
    { name: calcType?.toUpperCase(), href: `/${calcType}`, current: false },
    {
      name: regType?.toUpperCase(),
      href: `/${calcType}/${regType}`,
      current: false,
    },
    {
      name: depType?.toUpperCase(),
      href: `/${calcType}/${regType}/${depType}`,
      current: false,
    },
    {
      name: semType?.toUpperCase(),
      href: `/${calcType}/${regType}/${depType}/${semType}`,
      current: true,
    },
  ];

  useEffect(() => {
    if (calcType === "cgpa" || calcType === "gpa") {
      if (regType === "r2017" || regType === "r2021" || regType === undefined) {
        if (
          depType === "cse" ||
          depType === "civil" ||
          depType === "mech" ||
          depType === "ece" ||
          depType === "eee" ||
          depType === undefined
        ) {
          if (calcType === "cgpa" && depType) {
            navigate(`/${calcType}/${regType}/${depType}/final`);
          } else if (
            !(
              semType === "sem1" ||
              semType === "sem2" ||
              semType === "sem3" ||
              semType === "sem4" ||
              semType === "sem5" ||
              semType === "sem6" ||
              semType === "sem7" ||
              semType === "sem8" ||
              semType === undefined
            )
          ) {
            navigate(`/${calcType}/${regType}/${depType}`);
          }
        } else {
          navigate(`/${calcType}/${regType}`);
        }
      } else {
        navigate(`/${calcType}`);
      }
    } else {
      navigate("/error");
    }
  }, []);

  return (
    <nav className="flex px-5 py-6 md:px-6 lg:px-16" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page, index) => (
          <li key={index}>
            <div className="flex items-center">
              <ChevronRightIcon
                className={`flex-shrink-0 w-5 h-5 text-gray-400 ${
                  page.name ? "" : "hidden"
                }`}
                aria-hidden="false"
              />
              <Link
                to={page.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? "page" : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
