import React from "react";
import Department from "../components/department";
import Footer from "../components/footer";
import NavBar from "../components/navbar";

const DepartmentPage = () => {
  return (
    <div>
      <NavBar plain={false} />
      <Department />
    </div>
  );
};

export default DepartmentPage;
