import { FormikProps } from "formik";
import React from "react";

interface SubjectProps {
  subject: {
    name: string;
    code: string;
    credit: string;
  };
  index: number;
  formik: FormikProps<any>;
  name: string;
}

const SubjectComponent = ({
  subject,
  formik: { values, errors, touched, handleChange, handleBlur },
  index,
  name,
}: SubjectProps) => {
  return (
    <div className="my-2 md:my-4 lg:my-6">
      <div className="flex flex-col px-2 font-medium text-green-500 md:items-center md:justify-between md:flex-row md:px-5 lg:px-12">
        <div className="flex my-2 space-x-2">
          <h1>{index + 1}</h1>
          <h1 className="">{`${
            subject.name
          } - ${subject.code.toUpperCase()}`}</h1>
        </div>
        <div className="flex my-2 space-x-5">
          <div className="relative space-x-2">
            <label className="text-primary" htmlFor="Credit">
              Credit
            </label>
            <input
              type="number"
              id="Credit"
              maxLength={1}
              defaultValue={subject.credit}
              className="w-10 h-8 px-3 border-2 rounded outline-none text-primary"
            />
          </div>

          <div className="space-x-2">
            <label className="text-primary" htmlFor="Grade">
              Grade
            </label>
            <select
              name={name}
              onChange={handleChange}
              onBlur={handleBlur}
              className="h-8 px-1 border-2 rounded outline-none text-primary"
            >
              <option value={""}>Select</option>
              <option value={"91"}>O</option>
              <option value={"81"}>A+</option>
              <option value={"71"}>A</option>
              <option value={"61"}>B+</option>
              <option value={"51"}>B</option>
              <option value={"0"}>RA</option>
              <option value={"0"}>AB</option>
              <option value={"0"}>WH</option>
            </select>
            {errors[name] && touched[name] ? (
              <p className="mt-1 text-sm text-right text-red-500">
                Select a Grade
              </p>
            ) : (
              <p className="mt-1 text-sm text-right text-white">Selected</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectComponent;
