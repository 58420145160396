import React from "react";
import { AiOutlineWarning } from "react-icons/ai";
import NavBar from "../navbar";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/solid";
import NotFoundImage from "../../assets/not-found.png";

const NotFound = () => {
  return (
    <>
      <div className="flex flex-col items-center my-10 md:my-16 lg:my-20 xl:my-24">
        <h1 className="my-1 text-3xl font-black text-center text-primary md:text-5xl">
          <span className="text-green-500">CGPA / GPA</span> Calculator
        </h1>
        <Link
          to="/"
          className="flex justify-center my-4 font-medium cursor-pointer hover:underline"
        >
          <div className="flex items-center md:text-lg">
            <HomeIcon className="w-5 h-5 mr-2 text-black" />
            <p className="">Back to Home</p>
          </div>
        </Link>
        <div className="flex flex-col items-center justify-center space-y-4 md:flex-row">
          <div className="">
            <img height="300" width="300" src={NotFoundImage} alt="" />
          </div>
          <div>
            <h1 className="flex items-center pt-16 mb-2 text-2xl font-bold lg:text-5xl">
              <AiOutlineWarning style={{ marginRight: "10px" }} />
              404<span className="mx-2 text-primary"> Not Found</span>
            </h1>
            <p className="text-lg text-center text-primary md:text-3xl">
              Something went wrong!
            </p>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default NotFound;
