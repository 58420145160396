import React from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Link, useParams, useNavigate } from "react-router-dom";

interface Props {}

const Regulation = (props: Props) => {
  const { calcType } = useParams();

  const regTypes = [
    {
      name: "R 2017",
      period: "2017 - 2021",
      route: `/${calcType}/r2017`,
    },
    {
      name: "R 2021",
      period: "2021 - 2025",
      // route: `/${calcType}/r2021`,
    },
  ];
  return (
    <div className="px-5 lg:px-16">
      <h1 className="py-10 text-2xl font-medium">Select your Regulation</h1>
      <div className="flex flex-wrap justify-between md:w-4/6 lg:w-3/5 xl:w-2/5 ">
        {regTypes.map((item, index) => (
          <Link to={item.route ?? "/"} key={index}>
            <div
              key={index}
              className="flex flex-col items-center justify-center w-40 h-32 my-5 font-semibold text-center text-white bg-green-500 rounded-lg shadow-2xl cursor-pointer md:w-52 hover:bg-green-600"
            >
              <h2>{item.name}</h2>
              <p className="text-primary">{item.period}</p>
            </div>
            {item.name === "R 2021" && (
              <div className="flex space-x-1">
                <IoInformationCircleOutline />
                <h1 className="text-xs text-primary w-32 md:w-full font-medium">
                  New regulations will be available soon.
                </h1>
              </div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Regulation;
