interface SubjectTypes{
  [key:string]:{
    name:string;
    code:string;
    credit:number;
  }[];
}

export const AllSubjects:SubjectTypes = {
  'r2017csesem1': // semester1
  [
    {
      name: "Communicative English",
      code: "hs8151",
      credit: 4,
    },
    {
      name: "Engineering Mathematics - I",
      code: "ma8151",
      credit: 4,
    },
    {
      name: "Engineering Physics",
      code: "ph8151",
      credit: 3,
    },
    {
      name: "Engineering Chemistry",
      code: "cy8151",
      credit: 3,
    },
    {
      name: "Problem Solving and Python Programming",
      code: "ge8151",
      credit: 3,
    },
    {
      name: "Engineering Graphics",
      code: "eg8151",
      credit: 4,
    },
    // practicals
    {
      name: "Problem Solving and Python Programming Laboratory",
      code: "ge8161",
      credit: 2,
    },
    {
      name: "Physics and Chemistry Laboratory",
      code: "bs8161",
      credit: 2,
    },
  ],
  'r2017csesem2': // semester2
  [
    {
      name:'Technical English',
      code:"HS8251",
      credit:4,
    },
    {
      name:'Engineering Mathematics - II',
      code:"MA8251",
      credit:4,
    },
    {
      name:'Physics for Information Science',
      code:"PH8252",
      credit:3,
    },
    {
      name:'Basic Electrical,Electronics and Measurement Engineering',
      code:"BE8255",
      credit:3,
    },
    {
      name:'Environmental Science and Engineering',
      code:"GE8291",
      credit:3,
    },
    {
      name:'Programming in C',
      code:"CS8251",
      credit:3,
    },
    {
      name:'Engineering Practices Laboratory',
      code:"GE8261",
      credit:2,
    },
    {
      name:'C Programming Laboratory',
      code:"CS8261",
      credit:2,
    },
  ],
  'r2017csesem3': // semester2
  [
    {
      name:'Discrete Mathematics',
      code:"MA8351 ",
      credit:4,
    },
    {
      name:'Digital Principles and System Design',
      code:"CS8351 ",
      credit:4,
    },
    {
      name:'Data Structures',
      code:"CS8391",
      credit:3,
    },
    {
      name:'Object Oriented Programming',
      code:"CS8392",
      credit:3,
    },
    {
      name:'Communication Engineering',
      code:"EC8395",
      credit:3,
    },
    {
      name:'Data Structures Laboratory',
      code:"CS8381",
      credit:2,
    },
    {
      name:'Object Oriented Programming Laboratory',
      code:"CS8383",
      credit:2,
    },
    {
      name:'Digital Systems Laboratory',
      code:"CS8382",
      credit:2,
    },
    {
      name:'Interpersonal Skills and Speaking Laboratory',
      code:"HS8381",
      credit:1,
    },
  ],
  'r2017csesem4': // semester2
  [
    {
      name:'Probability and Queuing Theory',
      code:"MA8402",
      credit:4,
    },
    {
      name:'Computer Architecture',
      code:"CS8491",
      credit:3,
    },
    {
      name:'Database Management Systems',
      code:"CS8391",
      credit:3,
    },
    {
      name:'Object Oriented Programming',
      code:"CS8392",
      credit:3,
    },
    {
      name:'Communication Engineering',
      code:"EC8395",
      credit:3,
    },
    {
      name:'Data Structures Laboratory',
      code:"CS8381",
      credit:2,
    },
    {
      name:'Object Oriented Programming Laboratory',
      code:"CS8383",
      credit:2,
    },
    {
      name:'Digital Systems Laboratory',
      code:"CS8382",
      credit:2,
    },
    {
      name:'Interpersonal Skills and Speaking Laboratory',
      code:"HS8381",
      credit:1,
    },
  ]

}