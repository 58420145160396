import React from "react";
import { BsCalendar2Date } from "react-icons/bs";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const Home = () => {
  let currentDate = new Date();
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <div>
      <div className="flex flex-col items-center p-2 text-primary lg:mt-16 md:p-5">
        <h1 className="my-1 text-3xl font-black text-center md:text-5xl">
          Calculate your <span className="text-green-500">CGPA / GPA</span>{" "}
        </h1>
        <h1 className="mb-4 text-3xl font-black md:text-5xl">
          within a minute{" "}
        </h1>
        <p className="mx-auto font-semibold text-center text-md md:text-lg md:w-2/5">
          Calculate your GPA for each semester within a minute. It has inbuilt
          subject code & credits for each subject. Just choose your grade and
          make the magic happens.{" "}
        </p>
        <br />
        <div>
          <div className="flex my-2 space-x-3">
            <BsCalendar2Date className="mb-4 text-3xl font-black md:text-4xl" />
            <div className="flex flex-col">
              <h1 className="text-lg font-black leading-4">
                Today :{" "}
                <span className="font-bold text-green-500">{`${currentDate.getDate()}-${
                  currentDate.getMonth() + 1
                }-${currentDate.getFullYear()}`}</span>
              </h1>
              <h1 className="text-lg font-bold">
                {days[currentDate.getDay()]}
              </h1>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-around my-4 space-y-5 md:w-1/5">
          <Link to='/cgpa'>
          <div className="flex items-center justify-center px-6 py-4 bg-green-500 rounded-lg shadow-xl cursor-pointer hover:bg-green-600 ">
            <h1 className="text-lg font-semibold text-white md:text-center">
              Calculate CGPA
            </h1>
          </div></Link>
                <Link to="/gpa">
                <div className="flex items-center justify-center px-6 py-4 bg-green-500 shadow-lg cursor-pointer rounded-xl hover:bg-green-600 hover:bg-hover-600 ">
            <h1 className="text-lg font-semibold text-white md:text-center">
              Calculate GPA
            </h1>
          </div></Link>
        </div>
        <h3 className="my-1 font-medium">Let's select one and continue</h3>
      </div>
    </div>
  );
};

export default Home;
