import { RiInstagramLine, RiGithubLine, RiLinkedinFill } from "react-icons/ri";
interface FooterProps {
  bgColor?: string;
}

export default function Footer({bgColor="bg-teritary"}:FooterProps) {
  const navigation = [
    {
      name: "Instagram",
      href: "https://www.instagram.com/imuthaiyan/",
      icon: () =>  <div className="flex items-center justify-center p-2 bg-white rounded-full shadow-md hover:bg-gray-300 bg-white-500">
      <RiInstagramLine color="red" className="w-6 h-6" />
    </div>,
    },
    {
      name: "GitHub",
      href: "https://github.com/muthaiyanmani",
      icon: () =>  <div className="flex items-center justify-center p-2 bg-white rounded-full shadow-md hover:bg-gray-300 bg-white-500">
      <RiGithubLine color="black" className="w-6 h-6" />
    </div>,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/in/muthaiyanmani/",
      icon: () =>  <div className="flex items-center justify-center p-2 bg-white rounded-full shadow-md hover:bg-gray-300 bg-white-500">
      <RiLinkedinFill color="blue" className="w-6 h-6" />
    </div>,
    },
  ];
  return (
    <footer className={`${bgColor}`}>
      <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              target={"_blank"}
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-base font-semibold text-center text-primary">
          GPA Calculator | &copy; {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
}
