import React from 'react'
import BreadCrumbs from './breadCrumbs'

interface Props {
    plain?: boolean
}

const NavBar = ({plain=true}: Props) => {
    return (
      <>
        <nav className={`flex flex-col w-full xl:px-16 lg:px-10 px-6 py-4 font-sans text-center bg-white ${plain ?"" : "shadow"} sm:flex-row sm:text-left sm:justify-between sm:items-baseline`}>
        <div className="mb-2 sm:mb-0">
          <a href="/" className="text-2xl no-underline text-grey-darkest hover:text-blue-dark">{plain ? "": "CGPA/GPA Calc"}</a>
        </div>
       
      </nav>
       {plain ? null :<BreadCrumbs/>}
      </>
    )
}

export default NavBar
