import React from "react";
import NavBar from "../components/navbar";
import Subject from "../components/subject";

const SubjectPage = () => {
  return (
    <div>
      <NavBar plain={false} />
      <Subject />
    </div>
  );
};

export default SubjectPage;
