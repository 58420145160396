import React from "react";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import Semester from "../components/semester";

const SemesterPage = () => {
  return (
    <div>
      <NavBar plain={false} />
      <Semester />
    </div>
  );
};

export default SemesterPage;
