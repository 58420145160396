import React from "react";
import Footer from "../components/footer";
import Home from "../components/home";
import NavBar from "../components/navbar";

const HomePage = () => {
  return (
    <div>
      <NavBar />
      <Home />
      <Footer bgColor="bg-white" />
    </div>
  );
};

export default HomePage;
