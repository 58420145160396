import React from "react";
import { Link, useParams } from "react-router-dom";

interface Props {}

const Semester = (props: Props) => {
  const { calcType, regType, depType } = useParams();

  const semesterList = [
    {
      name: "Semester 1",
      route: `/${calcType}/${regType}/${depType}/sem1`,
    },
    {
      name: "Semester 2",
      route: `/${calcType}/${regType}/${depType}/sem2`,
    },
    {
      name: "Semester 3",
      route: `/${calcType}/${regType}/${depType}/sem3`,
    },
    {
      name: "Semester 4",
      route: `/${calcType}/${regType}/${depType}/sem4`,
    },
    {
      name: "Semester 5",
      route: `/${calcType}/${regType}/${depType}/sem5`,
    },
    {
      name: "Semester 6",
      route: `/${calcType}/${regType}/${depType}/sem6`,
    },
    {
      name: "Semester 7",
      route: `/${calcType}/${regType}/${depType}/sem7`,
    },
    {
      name: "Semester 8",
      route: `/${calcType}/${regType}/${depType}/sem8`,
    },
  ];
  return (
    <div>
      <div>
        <div className="px-5 lg:px-16">
          <h1 className="py-10 text-2xl font-medium">Select your Semester</h1>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 ">
            {semesterList.map((sem, index) => (
              <Link
                to={sem.route}
                key={index}
                className="flex flex-col items-center justify-center w-40 h-32 my-4 font-semibold text-center text-white bg-green-500 rounded-lg shadow-2xl cursor-pointer md:mb-10 md:w-52 hover:bg-green-600"
              >
                <h2>{sem.name}</h2>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Semester;
