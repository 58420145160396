import React from "react";
import NavBar from "../components/navbar";
import Regulation from "../components/regulation";

const RegulationPage = () => {
  return (
    <>
      <NavBar plain={false} />
      <Regulation />
    </>
  );
};

export default RegulationPage;
